<template>
    <div class="page-wrapper">
        <PageSection :loading="PAYMENT_LOADING">
            <div v-if="PAYMENT_DETAILS" class="col-md-8 offset-md-2">
                <h3>{{ t('prepay_success.title') }}</h3>
                <p class="mt-3 text-start success-body">
                    {{
                        t('prepay_success.sub_title', {
                            minutes: PAYMENT_DETAILS.minutes
                        })
                    }}
                </p>
                <p class="mt-3 text-start success-body important">
                    {{ t('prepay_success.how_to_use') }}
                </p>
                <h2 class="important">{{ t('prepay_success.phone_to_use') }}</h2>
                <p class="mt-3 text-start success-body">
                    {{
                        t('prepay_success.body', {
                            customer_phone_no: PAYMENT_DETAILS.customerPhone,
                            order_no: PAYMENT_DETAILS.orderNo,
                            amount: PAYMENT_DETAILS.amount,
                            email_address: PAYMENT_DETAILS.customerEmail
                        })
                    }}
                </p>
            </div>

            <h3 v-else>{{ t('prepay_success.not_found') }}</h3>

            <router-link :to="{ name: 'Route_PrepayOverview' }">
                {{ t('prepay_success.checkout_link') }}
            </router-link>
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import PageSection from '../../components/PageSection.vue';

    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    store.dispatch('pagePrepaySuccess/LOAD_PAYMENT_DETAILS', route.params.paymentId?.trim() || '');

    const PAYMENT_LOADING = computed(() => store.getters['pagePrepaySuccess/PAYMENT_LOADING']);
    const PAYMENT_DETAILS = computed(() => store.getters['pagePrepaySuccess/PAYMENT_DETAILS']);
</script>

<style lang="scss" scoped>
    .success-body {
        white-space: pre-line;
    }

    .important {
        color: red;
    }
</style>
